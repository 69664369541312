import { create } from "zustand";
import axios from "axios";

const homeStore = create((set) => ({
  coins: [],

  fetchCoins: async () => {
    // const res = await axios.get(
    //   "https://api.coingecko.com/api/v3/search/trending"
    // );

    const res = await axios.get(
      "https://sandbox-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?limit=2",
      {
        headers: {
          "X-CMC_PRO_API_KEY": "bbada1d2-e851-4024-b704-2f35e6da20b2",
          Accept: "application/json",
          "Accept-Encoding": "deflate, gzip",
        },
      }
    );

    res = res.json();
    const coins = res.data.coins.map((coin) => {
      // return {
      //   id: coin.item.id,
      //   name: coin.item.name,
      //   priceBtc: coin.item.price_btc.toFixed(10),
      // };
      return {
        name: coin.name,
        quote: coin.quote
      }
    });

    console.log(coins);
    set({ coins });
  },
}));

export default homeStore;
