import { GET_REWARDS, REWARDS_FAIL } from "../actions/types";

const initialState = {
  rewards: null,
};

function rewardsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_REWARDS:
      return {
        ...state,
        rewards: payload,
      };
    case REWARDS_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export default rewardsReducer;
