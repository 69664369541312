import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { setAlert } from "../actions/alert";
import { register } from "../actions/auth";
import PropTypes from "prop-types";

import { NotificationManager } from "react-notifications";

function SignUp({ setAlert, register, isAuthenticated, alert }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [registerState, setRegisterState] = useState(false);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  useEffect(() => {
    console.log(alert);
    if (alert.length > 0) {
      {
        const msg = alert.pop();
        if (msg.alertType === "success")
          NotificationManager.success(msg.msg, "Success", 3000);
        else NotificationManager.warning(msg.msg, "Warning", 3000);
      }
    }
  }, [alert]);

  const onSignUpClick = () => {
    console.log(email);
    console.log(password);
    if (email && password) {
      if (!isValidEmail(email)) {
        NotificationManager.warning(
          "Email address is invalid.",
          "Warning",
          3000
        );
      } else if (password.length < 8) {
        NotificationManager.warning(
          "Password length should be more than 8 letters.",
          "Warning",
          3000
        );
      } else {
        //send data
        register({ email, password });
      }
    } else {
      NotificationManager.warning(
        "Please set all fields for detail.",
        "Warning",
        3000
      );
      setAlert("Please set all fields for detail.");
    }
  };

  if (isAuthenticated) {
    NotificationManager.success(
      "SignUp Succeed! Please wait until confirm your role.",
      "success",
      3000
    );
    setTimeout(() => {
      setRegisterState(true);
    }, 3000);
  }

  if (registerState == true) {
    return <Navigate to="/login" />;
  }

  return (
    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              className="img-fluid none-img"
              alt="Sample image"
            />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            <form>
              <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                <img
                  src="img/dark-logo.png"
                  width={"40%"}
                  className="mb-4 text-center m-auto"
                  style={{ borderRadius: "50%" }}
                ></img>
              </div>

              <Link to="/" className="w-100">
                <h4 className="text-center ">Hope Mountain</h4>
              </Link>
              <p className="mt-2 text-center">
                This is for only admin. If you are not an admin of site, you
                don't need to sign here.
              </p>

              <div className="divider d-flex align-items-center my-4">
                {/* <p className="text-center fw-bold mx-3 mb-0">Or</p> */}
              </div>

              <div className="form-outline mb-4">
                <input
                  type="email"
                  id="form3Example3"
                  className="form-control form-control-lg"
                  placeholder="Enter a valid email address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <label className="form-label" for="form3Example3">
                  Email address
                </label>
              </div>

              <div className="form-outline mb-3">
                <input
                  type="password"
                  id="form3Example4"
                  className="form-control form-control-lg"
                  placeholder="Enter password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <label className="form-label" for="form3Example4">
                  Password
                </label>
              </div>

              {/* <div className="d-flex justify-content-between align-items-center">
                <div className="form-check mb-0">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    value=""
                    id="form2Example3"
                  />
                  <label className="form-check-label" for="form2Example3">
                    Remember me
                  </label>
                </div>
                <a href="#!" className="text-body">
                  Forgot password?
                </a>
              </div> */}

              <div className="text-center text-lg-start mt-4 pt-2">
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
                  onClick={onSignUpClick}
                >
                  Sign Up
                </button>
                <p className="small fw-bold mt-2 pt-1 mb-0">
                  Don't have an account?{" "}
                  <Link
                    to="/login"
                    className="font-bolder text-black color-black"
                  >
                    Log In
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

SignUp.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  alert: state.alert,
});

export default connect(mapStateToProps, { setAlert, register })(SignUp);
