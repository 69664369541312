import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./views/Home";

import { LanguageProvider } from "./components/container/Language";

import { Text } from "./components/languages/Text/Text";

import SignUp from "./views/SignUp";
import SignIn from "./views/SignIn";

import Alert from "./components/layout/Alert";

// Redux
import { LOGOUT } from "./actions/types";
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";

function App() {
  useEffect(() => {
    // check for token in LS when app first runs
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <div>
      <Provider store={store}>
        <Router>
          <LanguageProvider>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
            </Routes>
          </LanguageProvider>
        </Router>
      </Provider>
      <NotificationContainer />
    </div>
  );
}

export default App;
