import api from "../utils/api";
import { setAlert } from "./alert";
import { GET_REWARDS, REWARDS_FAIL } from "./types";

/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/

// Load Rewards
export const getRewards = () => async (dispatch) => {
  try {
    const res = await api.get("/rewards");

    dispatch({
      type: GET_REWARDS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

  }
};

// Register Rewards
export const editRewards = (formData) => async (dispatch) => {
  try {
    const res = await api.post("/rewards", formData);

    dispatch(setAlert("Rewards updated successfully", "success"));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: REWARDS_FAIL,
    });
  }
};
