import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "react-query";
// import BtcContext from '../views/Home';

const useGetCardData = (cryptoName, options) => {
  return useQuery(
    `${cryptoName}-card`,
    async () => {
      const response = await fetch(
        `https://api.coingecko.com/api/v3/coins/${cryptoName}`
      );
      return await response.json();
    },
    options
  );
};

export const formatPrice = (price) => {
  const formatConfig = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  return formatConfig.format(price);
};

const useCoinPrice = (cryptoName) => {
  const [price, setPrice] = useState(0);
  const { data, isLoading } = useGetCardData(cryptoName, {
    refetchInterval: 10000,
    staleTime: 10000,
  });
  useEffect(() => {
    if (isLoading) {
    } else {
      setPrice(data?.market_data?.current_price?.usd);
    }
  }, [data, isLoading]);
  return price;
};

const GetCryptoPrice = ({ cryptoName, updateBtcPrice = () => {} }) => {
  const price = useCoinPrice(cryptoName);
  useEffect(() => {
    updateBtcPrice(price);
  }, [price]);

  return <input className="crypto-price" value={price}></input>;
};

export default GetCryptoPrice;
